<template>
    <Navbar />
    <div class="container-fluid">
        <div class="home-full mt-3">
            <AlertErrors />
            <router-view class="mt-4"/>
        </div>
    </div>
</template>
<script>
import Sidebar from '../components/layout/Sidebar.vue'
import Navbar from '../components/layout/Navbar.vue'
import Breadcum from '../components/layout/Breadcum.vue'
export default {
    components: {
        Sidebar,
        Navbar,
        Breadcum
    }
}
</script>
<style>
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    width: 92% !important;
}
</style>