<template>
  <b-button class="referrals" @click="copyURL('https://app.upcademy.net/#/auth/signup/' + user.username)" variant="outline-light"
    ><img src="@/assets/icons/copy.svg" alt="" />
    <p class="referrals__text">Link Referido</p></b-button
  >
</template>

<script>
import { mapState } from 'vuex';
export default {
  methods: {
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        openNotification('Successfully copied');
      } catch ($e) {
        openNotification('Please, try later', 'error');
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
  }
}
</script>

<style scoped>
.btn {
  padding: 8px 12px 8px 8px;
  @media (max-width: 405px) {
    padding: 8px;
  }
}
.referrals {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px 8px 8px;
  background: #3bbeee;
  border: 1px solid #3bbeee;
  border-radius: 100px;
}

.referrals__text {
  margin: 0;
}

@media screen and (max-width: 405px) {
  .referrals__text {
    display: none;
  }
}
</style>
