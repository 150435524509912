<template>
  <header class="header">
    <div class="container-fluid">
        <div class="header__up">
        <img class="header__img" src="@/assets/images/logo.webp" alt="" />
        <div class="header__box">
          <LinkReferrals />
          <DropdownUser />
        </div>
      </div>
      <div class="header__down">
        <HeaderRoutes />
      </div>
    </div>
  </header>
</template>
<script>
import LinkReferrals from "./LinkReferrals.vue";
import DropdownUser from "./DropdownUser.vue";
import HeaderRoutes from "./HeaderRoutes.vue";

import { mapActions, mapState } from "vuex";
export default {
  components: {
    LinkReferrals,
    DropdownUser,
    HeaderRoutes,
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions("auth", ["logout", "getUserInfo"]),
    close() {
      this.logout().then(() => {
        this.$router.push({ name: "Signin" });
      });
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>
<style>
.header {
  padding: 2.4rem 4%;
  gap: 24px;
  background: rgba(255, 255, 255, 0.04);
}

.header__up {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 24px;
}

.header__img {
  width: 150px;
}

.header__box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
}

.header__down{
  margin-top: 20px;
}

@media screen and (max-width: 405px) {
  .header__box {
    gap: 8px;
  }
}
</style>
