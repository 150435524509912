const menuItems = [
    {
        name: "Dashboard",
        route: "Dashboard",
        allow: [2]
    },
    {
        name: "Academy",
        route: "Academy",
        allow: [2]
    },
    { 
        name: "Signal",
        route: "Signal",
        allow: [2]
    },
    { 
        name: "Trading-Plans",
        route: "Trading-Plans",
        allow: [2]
    },
    {
        name: "Trading-Live",
        route: "Trading-Live",
        allow: [2]
    },
    {
        name: "Team",
        route: "Team",
        allow: [2]
    },
    {
        name: "Membership",
        route: "Membership",
        allow: [2]
    },
    {
        name: "Transactions",
        route: "Transactions",
        allow: [2]
    },
    {
        name: "Chat",
        route: "Chat",
        allow: [2]
    },
    {
        name: "Tokens",
        route: "Tokens",
        allow: [2]
    },
    {
        name: "Profile",
        route: "Profile",
        allow: [2]
    },
    {
        name: "Dashboard",
        route: "Manage-Dashboard",
        allow: [1]
    },
    {
        name: "Membership",
        route: "Manage-Membership",
        allow: [1]
    },
    {
        name: "Academy",
        route: "Manage-Academy",
        allow: [1]
    },
    {
        name: "Trading-Live",
        route: "Manage-Trading-Live",
        allow: [1]
    },
    {
        name: "Signal",
        route: "Manage-Signal",
        allow: [1]
    },
    {
        name: "Transactions",
        route: "Manage-Transactions",
        allow: [1]
    },
    {
        name: "Tokens",
        route: "Manage-Tokens",
        allow: [1]
    },
    {
        name: "Users",
        route: "Manage-Users",
        allow: [1]
    },
    {
        name: "Purchases",
        route: "Manage-Purchases",
        allow: [1]
    },
    {
        name: "Kyc",
        route: "Manage-Kyc",
        allow: [1]
    },
    {
        name: "Withdrawals",
        route: "Manage-Withdrawals",
        allow: [1]
    },
]

export default menuItems