<template>
  <div class="drop" @click="toggleMenu">
    <img class="drop__avatar" src="@/assets/icons/avatar.svg" alt="" />
    <i
      class="drop__arrow b7-arrow-down"
      :class="menu === true ? 'drop__arrow-down' : ''"
    ></i>
  </div>
  <div v-show="menu" class="drop__menu">
    <div class="drop__menu-header">
      <p class="b-medium text-black">Opciones</p>
    </div>
    <div class="drop__menu-body">
      <router-link :to="{ name: 'Profile' }" @click="toggleMenu"
        >Perfil</router-link
      >
      <router-link to="#" @click="closeSession">Cerrar sesión</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ...mapActions('auth', ['logout']),
    closeSession () {
      this.logout().then(() => {
        this.$router.push({name: 'Signin'})
      })
    }
  }
}
</script>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { mapActions } from "vuex";

const menu = ref(false);

const toggleMenu = () => {
  menu.value = !menu.value;
};

const handleClickOutside = (event) => {
  if (!event.target.closest(".drop")) {
    menu.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.drop {
  cursor: pointer;
}
.drop__avatar {
  position: relative;
}
.drop__arrow {
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-radius: 75px;
  border: 0.75px solid rgba(236, 236, 238, 0.11);
  background: var(--brand-bg, #070721);
  width: 18px;
  height: 18px;
  display: grid;
  place-content: center;
}
.drop__arrow-down {
  transform: rotate(180deg);
}
.drop__menu {
  background-color: #fff;
  top: 54px;
  right: 0;
  position: absolute;
  width: 137px;
  background: #ffffff;
  box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  border-radius: 16px;
}
.drop__menu-header {
  padding: 8px 24px;
  border-bottom: 1px solid #ececee;
}
.drop__menu-body {
  padding: 8px 24px 16px;
  width: 100%;
  display: grid;
  gap: 8px;
}
.drop__menu-body a {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  display: block;
  color: #000;
  text-decoration: none;
}
.drop__menu-body a:hover {
  color: #ee8722;
  font-weight: 500;
}
</style>
