<template>
  <div class="routes">
    <div class="routes__item" v-for="(item, index) in menuItems"
        :key="index">
      <router-link
      class="menu-item"
        v-if="item.allow.includes(user.role)"
        :to="{ name: item.route, params: {username: user.username} }"
        >
        {{ item.name.replace(/-/g, ' ') }}
        </router-link>
    </div>
  </div>
</template>

<script>
import menuItems from './menuItems';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      menuItems: menuItems
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>

<style scoped>
.routes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
@media (max-width: 400px) {
  .routes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 385px) {
  .routes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.routes a {
  color: #b5c2d7;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}
.routes .router-link-exact-active {
  background-color: #ee8722 !important;
  font-weight: 500;
  color: #fff;
}
.routes__item {
  gap: 10px;
  margin-top: 10px;
}
.menu-item{
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 100px;
  gap: 10px;
}
</style>
